<template>
    <div>
      <h3>Payment Reports</h3>
  
      <div class="d-flex align-items-center">
        <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                
        <argon-button color="success" size="sm" class="ms-auto" @click="exportToExcel">Export to Excel</argon-button>
      </div>

      <div v-if="!isAgent" class="col-md-12">
        
        <select id="filter" name="filter" class="form-select-sm" v-model="selectedFilter" @change.prevent="filter">
          <option v-for="element in filters" :value="element.id" :key="element.id">
            {{ element.name }}
          </option>
        </select>
  
        <select v-if="!isAgent && selectedFilter == 5" id="filter2" name="filter2" class="form-select-sm" v-model="selectedFilter2" @change.prevent="filter2">
          <option v-for="element in filters2" :value="element.name" :key="element.id">
            {{ element.name }}
          </option>
        </select>
        &nbsp;
        <a href="#" @click.prevent="search">
          <i class="fa fa-magnifying-glass text-success text-sm opacity-10"></i>
        </a>
  
        <br><br>
        <input v-if="!isAgent" type="text" id="searchVal" name="searchVal" class="form-control" v-model="searchVal" placeholder="Search" @change.prevent="validate">
      </div>
  
      <div class="col-md-12">
        <label>Year</label>
        <select id="year" name="year" class="form-select" v-model="selectedYear" @change="search">
          <option v-for="element in years" :value="element.id" :key="element.id">
            {{ element.name }}
          </option>
        </select>
  
        <label>Month</label>
        <select id="months" name="months" class="form-select" v-model="selectedMonth" @change="search">
          <option v-for="element in months" :value="element.id" :key="element.id">
            {{ element.name }}
          </option>
        </select>
  
        <label>Period</label>
        <select id="period" name="period" class="form-select" v-model="selectedPeriod" @change="search">
          <option v-for="element in periods" :value="element.id" :key="element.id">
            {{ element.name }}
          </option>
        </select>
      </div>
      <br>
      <div class="col-md-12 scrollable-div">
        <div class="content">

          <ul class="ul">
            <li>
              <span class="td1 heading">
      
              </span>

              <span class="td3 heading">
                Policy # 
              </span>

              <span class="td3 heading">
                Medicare ID
              </span>

              <span class="td3 heading">
                Member
              </span>

              <span class="td2 heading">
                Submitted Date
              </span>

              <span class="td2 heading">
                Effective Date
              </span>

              <span class="td2 heading">
                Carrier
              </span>

              <span class="td3 heading">
                Agent
              </span>

              <span class="td1 heading">
                NPN
              </span>

              <span class="td3 heading">
                Status
              </span>

              <span class="td2 heading">
                Payment Type
              </span>

              <br>
            </li>
          </ul>

          <ul class="ul">
            <li v-for="element in elements" :key="element.id">
              <span class="td1">
                <i v-if="isOlderThan120Days(element.EffectiveDate)" class="fa fa-plus-circle fym-good"></i>
                <i v-else class="fa fa-minus-circle fym-alert"></i>
                {{ element.policy_age }} days
              </span>
              <span class="td3">{{ element.Policy_Number }}</span>
              <span class="td3">{{ element.MedicareID }}</span>
              <span class="td3">{{ element.member }}</span>
              <span class="td2">{{ new Date(element.SubmitDate).toLocaleDateString() }}</span>
              <span class="td2">{{ new Date(element.EffectiveDate).toLocaleDateString() }}</span>
              <span class="td2">{{ element.carrier }}</span>
              <span class="td3">{{ element.agent_name }}</span>
              <span class="td1">{{ element.AgentNPN }}</span>
              <span class="td3">
                <a v-if="element.appeal" @click.prevent="editAppeal(element.appeal)">
                  {{ element.Status }} <i class="fa fa-hand-point-up fym-fail"></i>
                </a>
                <a v-else @click.prevent="createAppeal(element.app_pol_key)">
                  {{ element.Status }}
                </a>
              </span>
              <span class="td2">{{ element.payment_type }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
<script>

  import ArgonButton from "@/components/ArgonButton.vue";
  import * as XLSX from "xlsx";

  var message = "";
  var selectedFilter = 4;
  var selectedFilter2 = 1;

  var selectedYear = String(new Date().getFullYear());
  var selectedMonth = new Date().getMonth();
  var selectedPeriod = '1';

  export default {
    name: "PaymentReports",
    data() {
      return {
        activeTab: "tab1", // Move activeTab here inside data()
        showMenu: false,
        role: sessionStorage.getItem('role'),
        message: "",
        elements: [],  // Initialize elements as an empty array
        elements2: [],
        filters: [],
        filters2: [],
        selectedFilter: 4,
        selectedFilter2: 1,
        searchVal: "",
        isCreateButtonDisabled: true,
        years: [{'id':2025,'name':'2025'}, {'id':2024,'name':'2024'}, {'id':2023,'name':'2023'}],
        selectedYear: String(new Date().getFullYear()),
        months: [{'id':1,'name':'January'}, {'id':2,'name':'February'},{'id':3,'name':'March'}, {'id':4,'name':'April'},
        {'id':5,'name':'May'}, {'id':6,'name':'June'},{'id':7,'name':'July'}, {'id':8,'name':'August'},
        {'id':9,'name':'September'}, {'id':10,'name':'October'},{'id':11,'name':'November'}, {'id':12,'name':'December'}
        ],
        selectedMonth: new Date().getMonth() + 1,
        periods: [{'id': '1', 'name': 'Period 1'}, {'id': '2', 'name': 'Period 2'}
        ],
        selectedPeriod: '1',
      }
    },
    computed: {
      now() {
        return new Date(); // or use Date.now() if you prefer timestamps
      },
      isFYMAdmin() {
        return this.role === 'FYM Admin';
      },
      isAgencyAdmin() {
        return this.role === 'Agency Admin';
      },
      isAgentManager() {
        return this.role === 'Agent Manager';
      },
      isAgent() {
        return this.role === 'Agent';
      }
    },
    methods: {
      filter() {
        this.$emit("filter");
      },
      filter2() {
        this.$emit("filter2");
      },
      search() {
        this.$emit("search");
      },
      validate() {
        this.$emit("validate");
      },
      isOlderThan120Days(date) {
        return new Date(date) < new Date(new Date().setDate(new Date().getDate() - 120));
      },
      editAppeal(appeal) {
        this.$emit("editAppeal", appeal);
      },
      createAppeal(app_pol_key) {
        this.$emit("createAppeal", app_pol_key);
      }
      ,    
      createManualEnrollment(npn) {
        this.$router.push('/ManualEnrollment/' + npn);
      },
      exportToExcel() {
        // Convert JSON data to worksheet
        const worksheet = XLSX.utils.json_to_sheet(this.elements);

        // Create a new workbook
        const workbook = XLSX.utils.book_new();

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet, "Policy Reports");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "payment_reports_data.xlsx");
      },
      editAppeal(id) {
        this.$router.push('/Appeal/' + id);
      },
      async getData(year, month, period, selectedFilter, searchVal) {

        //console.log(year, month, period, selectedFilter, searchVal);

        let response = await fetch("/apis/payment_reports/" + year + '/' + month + '/' + period + '/' + selectedFilter + '/' + searchVal);
        this.elements = await response.json();

        // Count occurrences of each status
        const statusCounts = this.elements.reduce((acc, item) => {
            acc[item.Status] = (acc[item.Status] || 0) + 1;
            return acc;
        }, {});

        //console.log(statusCounts);

        this.message = this.elements.length + ' Policies';

        // Append each category and count to the message
        const statusSummary = Object.entries(statusCounts)
            .map(([status, count]) => `${status}: ${count}`)
            .join(', ');

        // Append to this.message
        this.message += ` (${statusSummary})`;
        //console.log(this.message);

      },
      async getData2(year, month, period, selectedFilter, searchVal) {

        //console.log(year, month, selectedFilter, searchVal);

        let response2 = await fetch("/apis/manual_enrollments/" + year + '/' + month + '/' + period + selectedFilter + '/' + searchVal);
        this.elements2 = await response2.json();
      },
      async getLookups() {        
        let response4 = await fetch("/apis/get_lu/filters");
        this.filters = await response4.json();

        let response5 = await fetch("/apis/get_policy_reports_statuses");
        this.filters2 = await response5.json();
      },
      search() {   
        console.log('searching during ' + this.selectedYear + ' ' + this.selectedMonth + ' ' + this.selectedPeriod + ' ' + this.selectedFilter + ' for: ' + this.searchVal);   
        this.getData(this.selectedYear, this.selectedMonth, this.selectedPeriod, this.selectedFilter, this.searchVal);
        //this.getData2(this.selectedYear, this.selectedMonth, this.selectedPeriod, this.selectedFilter, this.searchVal);
      },
      filter() {
        //console.log(this.selectedFilter);
        this.searchVal = ''; 
      },
      filter2() {
        console.log(this.selectedFilter2);
      },
      validate() {
        this.isCreateButtonDisabled = !(this.selectedFilter === 1 && this.searchVal.trim() !== "");
      },

    },
    created() {
        this.getData(this.selectedYear, this.selectedMonth, this.selectedPeriod, 4,undefined);
        //this.getData2(selectedYear, selectedMonth, selectedPeriod, 4,undefined);
        this.getLookups();
    },
  };

</script>