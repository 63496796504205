<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>
    <div class="py-4 container-fluid">
      
      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1"> {{elements[0]?.firstname}} {{elements[0]?.lastname}} </h5>

                <p class="mb-0 font-weight-bold text-sm"> </p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <form id="profile-form" role="form" method="POST" enctype="multipart/form-data">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>
                <argon-button color="success" size="sm" class="ms-auto" @click.prevent="submitData()">Save</argon-button>
              </div>
            </div>
            <div class="card-body">
              <p class="text-uppercase text-sm">User Information</p>
              <div class="row">
                <div class="col-md-6">
                  <label for="email" class="form-control-label"
                    >Email address</label
                  >
                  <input class="form-control form-control-default invalid" id="email" name="email" type="email" :value="elements[0]?.email" readonly autocomplete="email"/>
                </div>
                
                <div class="col-md-6">                  
                </div>

                <div class="col-md-6">
                  <label for="firstname" class="form-control-label"
                    >First name</label
                  >
                  <input class="form-control form-control-default invalid" id="firstname" name="firstname" type="text" :value="elements[0]?.firstname"/>
                </div>
                <div class="col-md-6">
                  <label for="lastname" class="form-control-label"
                    >Last name</label
                  >
                  <input class="form-control form-control-default invalid" id="lastname" name="lastname" type="text" :value="elements[0]?.lastname"/>
                </div>

                <div class="col-md-6">
                  <label for="sysrole" class="form-control-label"
                    >Role</label
                  >
                  <span>
                    <select id="sysrole" name="sysrole" class="form-select" :value="selectedUserRole" disabled>
                      <option v-for="element in user_roles" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>

                <div class="col-md-6">
                  <label for="ssn" class="form-control-label"
                    >SSN</label
                  >
                  <span>
                    <input class="form-control form-control-default invalid" id="ssn" name="ssn" type="text" :value="elements[0]?.ssn"/>
                  </span>
                </div>

                <div class="col-md-6" hidden>
                  <label for="agency_id" class="form-control-label"
                    >Agency</label
                  >
                  <span>
                    <select id="agency_id" name="agency_id" class="form-select" :value="selectedAgency" disabled>
                      <option v-for="element in agencies" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>

                </div>

                <div class="col-md-6">
                  <label for="sysgroup" class="form-control-label"
                    >Group</label
                  >
                  <span>
                    <select id="sysgroup" name="sysgroup" class="form-select" :value="selectedGroup" disabled>
                      <option v-for="element in user_groups" :value="element.code" :key="element.code">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                </div>

                <div class="col-md-6">                  
                </div>

               </div>

            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
    </section>
  </main>
</template>

<script>
import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const body = document.getElementsByTagName("body")[0];

var message = "User Profile";
const sysname = sessionStorage.getItem("sysname");

var selectedAgency;
var selectedUserRole;
var selectedGroup;

export default {
  name: "Profile",
  props: ['id'],
  data() {
    return {
      showMenu: false,
      elements: [],
      agencies: [],
      selectedAgency,
      user_roles: [],
      user_groups: [],
      selectedUserRole,    
      selectedGroup,      
      message,
      sysname,
    }
  },
  methods: {
    serializeForm(){
      
      let myForm = document.getElementById('profile-form'); 
      let formData = new FormData(myForm);
      const data = {}; 

      // Temporarily enable the field
      this.isFieldDisabled = false;

      for (let [key, val] of formData.entries()) {
        Object.assign(data, {[key]: val});
      }
      
      // Optionally re-disable the field
      this.isFieldDisabled = true;

      return data;
    },
    async checkAuth() {
      let response = await fetch("/apis/auth");
      this.loggedin = await response.json();
      if(this.loggedin =="notloggedin") {
        location.href="/home";
      }
    },
    async getData() {
      let response = await fetch("/apis/profile_load");
      this.elements = await response.json();

      this.selectedUserRole = this.elements[0]['sysrole']; 
      this.selectedGroup = this.elements[0]['sysgroup']; 
      this.selectedAgency = this.elements[0]['agency_id']; 
      this.ssn = this.elements[0]['ssn']; 
    },
    async getLookups() {
        
      let response1 = await fetch("/apis/view/Agencies");
      this.agencies = await response1.json();

     let response2 = await fetch("/apis/get_lu/user_roles");
     this.user_roles = await response2.json();

     let response3 = await fetch("/apis/get_lu/user_groups");
     this.user_groups = await response3.json();
    },
    submitData: function() {
      fetch('/apis/profile_update', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.serializeForm()),
     })
      .then((res) => res.json())
      .then((data) => {
        console.log(data.result);
        if (data.result === 'profile_updated') {
          //router.push('/profile');
          this.message = data.message;
          this.getData();
        }
        else {
          router.push('/');
        }
      })
      .catch((err) => console.log(err));
    },
  },
  components: { Navbar,  ArgonButton },
  created() {

    this.checkAuth();

    this.getData();
    this.getLookups();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
    body.classList.add("profile-overview");
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
    body.classList.remove("profile-overview");
  }
  
};
</script>
