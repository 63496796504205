<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">

        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">                
                <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{company}} Enrollments</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div v-if="!isAgent" class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>
                <argon-button color="success" size="sm" class="ms-auto" @click="exportToExcel">Export to Excel</argon-button>              
              </div>
            </div>
            <div class="card-body">

              <div class="col-md-12" hidden>
                select s.ConfirmationNumber 
                from fym_pubsub.enrollments_sftp s
                left join fym_pubsub.enrollments e on (trim(e.enrollmentCode) = trim(s.ConfirmationNumber))
                where filename = 'sunfire_20241116_1731741630_0001472.csv'
                and e.enrollmentCode is null
              </div>

              <ul class="ul">
                  <li>

                    <span class="td1">
                      <input type="checkbox" :value="option" v-model="selectedItems" />
                    </span>

                    <span class="td5 heading">
                      Filename
                    </span>
                       
                    <span class="td1 heading">
                      Sunfire sftp file
                    </span>

                    <span class="td1 heading">
                      Enrollments sent to Enrollhere
                    </span>

                    <span class="td2 heading">
                      Enrollments Not sent to Enrollhere
                    </span>

                    <span class="td1 heading">
                      Policy Reports
                    </span>

                    <span class="td2 heading">
                      Not in Policy Reports
                    </span>

                  </li>

              </ul>

              <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                  <li>
                    
                    <span class="td1">
                      <input type="checkbox" :value="option" v-model="selectedItems" />
                    </span>

                    <span class="td5" title="Filename">
                      {{element.filename}}
                    </span>
                       
                    <span class="td1">
                      {{element.sunfire_sftp}}
                    </span>

                    <span class="td1">
                      {{element.enrollments}}
                    </span>

                    <span class="td2">
                      <a class="hover-pointer" @click.prevent="drilldown1(element.filename)">{{element.variance}}</a>
                    </span>

                    <span class="td1">
                      {{element.policy_reports}}
                    </span>
                   
                    <span class="td2">
                      <a class="hover-pointer" @click.prevent="drilldown2(element.filename)">{{element.variance2}}</a>
                    </span>

                    <br>
                  </li>
              </ul>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

import * as XLSX from "xlsx";

const company = sessionStorage.getItem("company");
var message = "";
var filter = "";

export default {
  name: "Enrollments",
  data() {
    return {
      showMenu: false,
      elements: [],
      company,
      message,
      filter,
      role: sessionStorage.getItem('role'),
      selectedAgency: '0',
      selectedStatus: '0'
    }
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    serializeForm(){
      
      let myForm = document.getElementById('search-form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async getData() {
      let response = await fetch('/apis/Enrollments');
      this.elements = await response.json();
      this.message = this.elements.length + ' Files';
    },
    async newelement(type) {
      router.push({ name: type, params: {id: 0}});
    },
    async drilldown1(filename) {
      let response = await fetch('/apis/Enrollments_variance/' + filename);
      this.elements = await response.json();
      this.message = this.elements.length + ' Enrollments';
      //this.$router.push('/drilldown/' + filename);
    },
    async drilldown2(filename) {
      let response = await fetch('/apis/Enrollments_variance2/' + filename);
      this.elements = await response.json();
      this.message = this.elements.length + ' Enrollments';
      //this.$router.push('/drilldown/' + filename);
    },
    exportToExcel() {
      // Convert JSON data to worksheet
      const worksheet = XLSX.utils.json_to_sheet(this.elements);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();

      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Missing Enrollments");

      // Generate Excel file and trigger download
      XLSX.writeFile(workbook, "missing_enrollments.xlsx");
    }
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>