<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">System links</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>
                <argon-button v-if="1==0" color="success" size="sm" class="ms-auto" @click.prevent="newelement()">Create Link</argon-button>              
              </div>
            </div>
            <div class="card-body">
              
              <ul class="ul">
                <li>
                  <span class="td1"> 
                  </span>

                  <span class="td1">                      
                  </span>

                  <span class="td3 heading">
                  </span>

                </li>

              </ul>

                <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                    <li>
                      <span class="td1">
                        <a :href="element.link" target="_blank">
                          <img width="100px" :src="element.img"/>
                        </a>
                      </span>

                      <span class="td1">                      
                      </span>

                      <span class="td3">
                        <a :href="element.link" target="_blank">
                          {{element.sys}}
                        </a>
                      </span>

                      <span class="td3">                      
                        <input :id="'sysun_' + element.id" name="username" class="form-control form-control-default invalid" type="email" placeholder="Username" autocomplete="off" :value="element.sysusername"/>
                      </span>
                      &nbsp;
                      <a :id="'sysuncp_' + element.id"><img :id="'sysimg_' + element.id" src="/img/copy-icon.png" width="20px" title="Copy Username" @click="copyTxtToClipboard('sysun_' + element.id)"></a>
                      &nbsp;
                      &nbsp;
                      <span class="td3">                                            
                          <input :id="'syspw_' + element.id" name="syspassword" class="form-control form-control-default invalid" type="password" placeholder="Password" autocomplete="off" :value="element.syspassword"/>
                      </span>
                      &nbsp;
                      <a :id="'syspwcp_' + element.id"><img src="/img/copy-icon.png" width="20px" title="Copy Password" @click="copyTxtToClipboard('syspw_' + element.id)"></a>
                      &nbsp;
                      <argon-button color="success" size="sm" class="ms-auto" @click.prevent="submitData(element.id)">Save</argon-button>

                      <br><br>
                    </li>
                </ul>

              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

const company = sessionStorage.getItem("company");
var message = "";

export default {
  name: "links",
  data() {
    return {
      showMenu: false,
      elements: [],
      company,
      message,
      username: '',
      password: '',
      role: sessionStorage.getItem('role')
    }
  },
  computed: {
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    async getData() {
      let response = await fetch("/apis/links");
      this.elements = await response.json();
      this.message = this.elements.length + ' links';
    },
    async newelement() {
      router.push({ name: 'Links', params: {id: 0}});
    },
    async saveCreds() {
      let response = await fetch("/apis/Link_update");
      this.elements = await response.json();
      this.message = 'credentials updated';
    },
    copyTxtToClipboard(fld) {
      var txt = document.getElementById(fld).value; 
      navigator.clipboard.writeText(txt);
      console.log('copied');            
    },
    submitData: function(id) {

      fetch('/apis/Link_update/' + id, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          username: document.getElementById('sysun_' + id).value, // Assuming `username` is a data property in Vue
          password: document.getElementById('syspw_' + id).value  // Assuming `password` is a data property in Vue
        }),
     })
     .then((res) => res.json())
      .then((data) => {
        console.log(data.result);
        if (data.result === 'Link_updated') {
          this.message = data.message;
          this.getData();
        }
        else {
          router.push('/');
        }
      })
      .catch((err) => console.log(err));
    },
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>