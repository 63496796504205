<template>



  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{this.company}} Policy Data</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>

    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              
            </div>
            <div class="card-body">
              
              <div class="tabs">
                <!-- Tab Links -->
                <ul class="tab-links">
                  <li :class="{ active: activeTab === 'tab1' }">
                    <button @click.prevent="changeTab('tab1')">Payment Reports</button>
                  </li>
                  <li v-if="isFYMAdmin" :class="{ active: activeTab === 'tab2' }">
                    <button @click.prevent="changeTab('tab2')">As Earned</button>
                  </li>
                  <li v-if="isFYMAdmin" :class="{ active: activeTab === 'tab3' }">
                    <button @click.prevent="changeTab('tab3')">HRA</button>
                  </li>
                  <li v-if="isFYMAdmin" :class="{ active: activeTab === 'tab4' }">
                    <button @click.prevent="changeTab('tab4')">UNL</button>
                  </li>
                  <li v-if="isFYMAdmin" :class="{ active: activeTab === 'tab5' }">
                    <button @click.prevent="changeTab('tab5')">PDP</button>
                  </li>

                </ul>

                <!-- Tab Content -->
                <div class="tab-content">

                  <PaymentReports v-if="activeTab === 'tab1'"/>

                  <AsEarnedData v-if="isFYMAdmin && activeTab === 'tab2'" />

                  <HRAData v-if="isFYMAdmin && activeTab === 'tab3'" />

                  <UNLData v-if="isFYMAdmin && activeTab === 'tab4'" />

                  <PDPData v-if="isFYMAdmin && activeTab === 'tab5'" />
                </div>
              </div>

              </div>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
    </div>
  </main>

</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

import "@/assets/css/fontawesome.css";

import PaymentReports from "./components/PaymentReports.vue";
import AsEarnedData from "./components/AsEarned.vue";
import HRAData from "./components/HRA.vue";
import UNLData from "./components/UNL.vue";
import PDPData from "./components/PDP.vue";

import { ref } from "vue";

const activeTab = ref("tab1"); // Default active tab

export default {
  name: "PolicyReports",
  components: {
    PaymentReports,
    AsEarnedData,
    HRAData,
    UNLData,
    PDPData,
    Navbar, 
    ArgonButton
  },
  data() {
    return {
      activeTab: "tab1",
      showMenu: false,
      role: sessionStorage.getItem("role") || "", 
      company: sessionStorage.getItem("company") || "Unknown Company"
    }
  },
  computed: {
    now() {
      return new Date(); // or use Date.now() if you prefer timestamps
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    changeTab(tab) {
      if (this.activeTab !== tab) {
        this.activeTab = tab;
      }
    },
  },
  created() {
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>

<style>

  .scrollable-div {
    width: 1024px; /* Fixed width */
    overflow-x: scroll; /* Enables horizontal scrolling */
    overflow-y: hidden; /* Hides vertical scrolling */
    white-space: nowrap; /* Prevents content from wrapping */
    border: 1px solid #ddd; /* Optional: For visual clarity */
    padding: 10px; /* Optional: For spacing inside the div */
  }

  .scrollable-div::-webkit-scrollbar {
    height: 10px; /* Makes scrollbar visible and sets height */
  }

  .scrollable-div::-webkit-scrollbar-thumb {
    background: #888; /* Style the scrollbar thumb */
    border-radius: 5px; /* Rounded edges */
  }

  .tab-links {
    list-style: none; /* Remove bullet points */
    padding: 0;
    display: flex; /* Align tabs in a row */
    gap: 10px; /* Add space between tabs */
  }

  .tab-links button {
    background: none;
    border: none;
    font-size: 14px;
    font-weight: bold;
    color: #333536;
    cursor: pointer;
    padding: 10px 15px;
    transition: color 0.3s ease-in-out;
}

.tab-links button:hover {
  color: #0f628f;
}

.tab-links .active button {
  background: #0f628f;
  color: #ffffff;
  border-radius: 3px 3px 0px 0px;
}

</style>