<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main>
    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-album-2 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">Agent Rate</h5>

                <p class="mb-0 font-weight-bold text-sm"></p>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-8">
          <div class="card">
            <form id="form" role="form" method="POST">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p class="mb-0">{{ message }}</p>
                <argon-button color="success" size="sm" class="ms-auto" @click.prevent="submitData()"
                  >Save</argon-button
                >
                <router-view></router-view>
              </div>
            </div>
            <div class="card-body">
              
              <div class="row">
              
                <div class="col-md-6">
                  
                  <label for="rate_type" class="form-control-label"
                    >Rate type
                  </label><br>
                  <span class="td11">
                    <select id="rate_type_id" name="rate_type_id" class="form-select" :value="selectedRateType">
                      <option v-for="element in rate_types" :value="element.id" :key="element.id" :id="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>

                </div>
              
              <div class="col-md-10">
                  <label for="products" class="form-control-label"
                    >Product
                  </label><br>
                  <span class="td11">
                    <select id="product" name="product" class="form-select" :value="selectedProduct">
                      <option v-for="element in products" :value="element.id" :key="element.id" :id="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Rate
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="rate" name="rate" type="number" v-model="elements[0].rate"/>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Effective From Date
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="effective_from" name="effective_from" type="date" @blur="validateEFDate()" :min="effective_from_min" :max="effective_to_max" v-model="elements[0].effective_from"/>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate" class="form-control-label"
                    >Effective To Date
                  </label><br>
                  <span class="td11">
                    <input class="form-control form-control-default invalid" id="effective_to" name="effective_to" type="date" @blur="validateETDate()" :min="effective_to_min" :max="effective_to_max" v-model="elements[0].effective_to"/>
                  </span>
                  
              </div>

              <div class="col-md-10">
                  <label for="rate_status" class="form-control-label"
                    >Rate status
                  </label><br>
                  <span class="td11">
                    <select id="rate_status" name="rate_status" class="form-select" v-model="selectedRateStatus">
                      <option v-for="element in rate_statuses" :value="element.name" :key="element.id" :id="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </span>
                  
              </div>

            </div>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

var message = "Agent Rate";

export default {
  name: "AgentRate",
  props: ['id','agent_id'],
  data() {
    return {
      auditTrail: [],
      showMenu: false,
      role: sessionStorage.getItem('role'),
      elements: [{id:0, rate: 0, effective_from: this.nextPayPeriod(), effective_to:'2030-01-01'}],
      rate_types: [],
      selectedRateType: 1,
      products: [],
      selectedProduct: 1,
      message,
      agency_rate: 0,
      agency_rate_type: 1, 
      rate_statuses: [{'id':'current','name':'current'}, {'id':'previous','name':'previous'}, {'id':'future','name':'future'}],
      selectedRateStatus: 'current',
      my_rates: [],
      effective_from_min: this.nextPayPeriod(),
      effective_from_max: '2030-01-01',
      effective_to_min: this.nextPayPeriod(),
      effective_to_max: '2030-01-01'
    }
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    logChanges() {
      for (const key in this.elements[0]) {
        if (this.elements[0][key] !== this.originalElement[key]) {
          const oldVal = encodeURIComponent(this.originalElement[key]);
          const newVal = encodeURIComponent(this.elements[0][key]);

          this.auditTrail.push({ field: key, oldValue: oldVal, newValue: newVal, timestamp: new Date().toISOString() });

          fetch(`/apis/audit/at_agent_rates/${this.$route.params.agent_id}/${this.$route.params.id}/${key}/${oldVal}/${newVal}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(this.serializeForm())
          });
        }
      }
    },
    set_status() {
      var formattedDate = new Date().toISOString().split('T')[0]; // 'YYYY-MM-DD'
      //console.log(formattedDate);
      if (this.elements[0].effective_from < formattedDate && this.elements[0].effective_to < formattedDate) {      
        this.selectedRateStatus = 'previous';
      }
      else if (this.elements[0].effective_from < formattedDate && this.elements[0].effective_to > formattedDate) {      
        this.selectedRateStatus = 'current';
      }
      else if (this.elements[0].effective_from > formattedDate && this.elements[0].effective_to > formattedDate) {      
        this.selectedRateStatus = 'future';
      }
    },
    validateEFDate() {
      if(!this.isFYMAdmin && (this.elements[0].effective_from < this.nextPayPeriod())) {
        alert('You can only schedule a rate change from the next pay period.');
        this.elements[0].effective_from = this.nextPayPeriod();
      }
      this.set_status();
    },
    validateETDate() {
      if(this.elements[0].effective_to > '2030-01-01') {
        alert('You can only schedule a rate that is effective until 2030-01-01.');
        this.elements[0].effective_to = '2030-01-01';
      }
      this.set_status();
    },
    formattedDate(date) {
      if (!(date instanceof Date) || isNaN(date)) {
        date = new Date();
      }
      return date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0');
    },
    nextPayPeriod() {
      var td = new Date();
      var nextPP = String(td.getFullYear()) + '-' + String(td.getMonth() + 1) + '-' + String(16); 
      if(td.getDate() > 15) {        
        nextPP = String(td.getFullYear()) + '-' + String(td.getMonth() + 2) + '-' + String(1);
      }
      return nextPP;
    },
    async getAgentRates() {        
      let response = await fetch("/apis/view/AgentRates/" + this.$route.params.agent_id);
      this.my_rates = await response.json();
    },
    serializeForm(){
      
      let myForm = document.getElementById('form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async checkAuth() {
      let response = await fetch("/apis/auth");
      this.loggedin = await response.json();
      if(this.loggedin =="notloggedin") {
        location.href="/home";
      }
    },
    async getData() {
      let response = await fetch("/apis/get_rate/" + this.$route.params.id);
      this.elements = await response.json();
      this.selectedProduct = this.elements[0]['product_id'];
      this.selectedRateType = this.elements[0]['rate_type_id'];
      this.selectedRateStatus = this.elements[0]['rate_status'];

      this.originalElement = JSON.parse(JSON.stringify(this.elements[0]));

    },
    async getLookups() {
      let response = await fetch('/apis/get_lu/products');
      this.products = await response.json();

      let response2 = await fetch('/apis/get_lu/rate_types');
      this.rate_types = await response2.json();

      let res3 = await fetch('/apis/get_agency_rate');
      this.rate = await res3.json();
      
      this.agency_rate = this.rate[0]['rate'];
      this.agency_rate_type = this.rate[0]['rate_type'];
    },
    validateForm() {
      var val = true;

      const items = ['rate'];

      for (const item of items) {

        if(document.getElementById(item).value === '') {

          var pcit = this.convertToTitleCase(item).replace('_',' ');

          var msg = 'Please enter a value in the ' + pcit + ' field';
          document.getElementById(item).focus();
          this.message = msg;
          val = false;
        }

        if(document.getElementById(item).value > Number(this.agency_rate)) {

          var pcit = this.convertToTitleCase(item).replace('_',' ');

          var msg = 'Rate cannot be above Agency rate of ' + this.agency_rate;
          document.getElementById(item).focus();

          this.message = msg;
          val = false;
        }

      }

      return val;

    },
    convertToTitleCase(str) {
      if (!str) {
          return ""
      }

      return str.toLowerCase().split(' ').map(function (word) {
        return word.charAt(0).toUpperCase().concat(word.substr(1));
      }).join(' ');
    },
    submitData: function() {
      
      if (this.validateForm()) {

        var action = 'create';
        var api = 'create/' + this.$route.params.agent_id;

        if (this.$route.params.id > 0) {
          action = 'update';
          api = 'update/' + this.$route.params.id + '/' + this.$route.params.agent_id;
        }

        fetch('/apis/AgentRate_' + api, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.serializeForm()),
        })
      
        .then((res) => res.json())
        .then((data) => {
          if (data.result === 'AgentRate_' + action + 'd') {

            if(action === 'update') {

              //console.log("originalElement:", this.originalElement);
              //console.log("elements[0]:", this.elements[0]);

              this.logChanges();
            }
            this.message = data.message;
            router.push('/Agent/' + this.$route.params.agent_id);
          }
          else {
            router.push('/');
          }
        })
        .catch((err) => console.log(err));
      }
      else {
        console.log('Rate form validation failed.');
      }
    },
  },
  components: { Navbar,  ArgonButton },
  created() {
    //console.log(this.formattedDate(new Date()));
    this.checkAuth();
    
    if (this.$route.params.id > 0) {
      this.getData();
    }
    else {
      this.elements[0].id = "0";
    }
    this.getAgentRates();
    this.getLookups();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>