<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">                
                <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">{{company}} Agents</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div v-if="!isAgent" class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>                
                <argon-button v-if="isFYMAdmin" color="success" size="sm" class="ms-auto" @click.prevent="newelement('Agent')">Create Agent</argon-button>
              </div>
            </div>
            <div class="card-body">

              <div v-if="!isAgent" class="col-md-12">
                <form id="search-form" role="form" method="POST" enctype="multipart/form-data" @submit.prevent="search()">                  
                  <a id="search_link" name="search_link" href=# @click.prevent="search()" title="search" hidden><i class="ni ni-zoom-split-in text-info text-sm opacity-10"></i></a>
                  <input id="search" name="search" type="text" class="form-control form-control-default invalid" title="Search" placeholder="Search" @keyup.prevent="search()">
                </form>
              </div>

              <ul class="ul">
                  <li>
                    <span class="td1"> 
                    </span>

                    <span class="td1 heading">
                      Agent NPN
                    </span>
                       
                    <span class="td2 heading" @click="sortBy('firstname')">
                      Firstname
                      <i :class="getSortIcon('firstname')"></i>
                    </span>

                    <span class="td3 heading" @click="sortBy('lastname')">
                      Lastname
                      <i :class="getSortIcon('lastname')"></i>
                    </span>

                    <span v-if="isFYMAdmin" class="td1 heading">
                      Agency
                    </span>

                    <span class="td3 heading">
                      Status                      
                    </span>

                    <span class="td1 heading">
                      Agent rate
                    </span>

                    <span class="td4 heading">
                      Systems
                    </span>

                  </li>

                  <li v-if="!isAgent">
                    <span class="td1"> 
                    </span>

                    <span class="td1 heading">                    
                    </span>
                    
                    <span class="td2 heading">
                    </span>
                    
                    <span class="td3 heading">
                    </span>

                    <span v-if="isFYMAdmin" class="td1 heading">
                      <select id="agency_select" class="form-select" v-model="selectedAgency" @change="search2(selectedAgency, selectedStatus, selectedSystems)">
                        <option value="0" selected>All</option>
                        <option value="1">FYM</option>
                        <option value="3">Oasis</option>
                        <option value="4">Collins</option>
                        <option value="5">Medcora</option>
                        <option value="6">Wise Choice</option>
                        <option value="7">Aspire</option>
                        <option value="8">HIP</option>
                        <option value="9">Csipkes</option>
                        <option value="10">PBG</option>
                      </select>
                    </span>

                    <span v-if="!isAgent" class="td3 heading">
                      <select id="status_select" class="form-select" v-model="selectedStatus" @change="search2(selectedAgency, selectedStatus, selectedSystems)">
                        <option value="0" selected>All</option>
                        <option value="1">Active</option>
                        <option value="2">Contracting</option>
                        <option value="3">Inactive</option>
                        <option value="4">Did not finish</option>
                        <option value="5">Terminated</option>
                        <option value="6">Terminated with cause</option>
                        <option value="7">Paused</option>                   
                      </select>
                    </span>

                    <span class="td1 heading">
                    </span>

                    <span v-if="isFYMAdmin || isAgencyAdmin" class="td4 heading">
                      <select id="systems_select" class="form-select" v-model="selectedSystems" @change="search2(selectedAgency, selectedStatus, selectedSystems)">
                        <option value="0" selected>All</option>
                        <option value="1">No One Password</option>
                        <option value="2">No Enrollhere</option>
                        <option value="3">No Sunfire</option>
                        <option value="4">No Bill ID</option>
                        <option value="5">No Signed contract</option> 
                        <option value="6">Awaiting Signed contract</option>                     
                      </select>
                    </span>
                    &nbsp;
                    <span v-if="isFYMAdmin" class="td1 heading">
                      <i v-if="selectedAgency !== '0'" id="copy_emails" class="hover-pointer fym-almostgood fa-solid fa-envelope" @click="copyEmailsToClipboard"></i>
                    </span>
                    
                  </li>

              </ul>

              <ul class="ul" v-for="element in elements" v-bind:key="element.id">
                  <li>
                    <span class="td1">
                      <i v-if="element.sysrole===0" class="fa fa-user-md text-dark text-sm opacity-10" title="FYM Admin"></i>&nbsp;
                      <i v-else-if="element.sysrole===1" class="fa fa-users text-primary text-sm opacity-10" title="Agency Admin"></i>&nbsp;
                      <i v-else-if="element.sysrole===2" class="fa fa-users text-info text-sm opacity-10" title="Agent Manager"></i>&nbsp;
                      <i v-else class="fa fa-user text-success text-sm opacity-10" title="Agent"></i>&nbsp;
                      <router-link :to="{ name: 'Agent', params: {id: element.id}}" class="font-weight-bold">
                        <i class="hover-pointer fa fa-ellipsis-v text-xs"></i>
                        &nbsp;<a
                        href="#"
                        class="text-secondary font-weight-bold text-xs fym-almostgood"
                        data-toggle="tooltip"                   
                        > View</a>
                      </router-link>
                    </span>
                        
                    <span class="td1 hover-pointer" @click="copy_npn(element.agent_npn)" title="Copy NPN to clipboard">
                      {{element.agent_npn}}
                    </span>
                       
                    <span class="td2" :title="element.phone">
                      {{element.firstname}}
                    </span>

                    <span class="td3" :title="element.email">
                      {{element.lastname}}
                    </span>

                    <span v-if="isFYMAdmin" class="td1" :title="element.agent_type">
                      {{element.agency}}
                    </span>

                    <span 
                      class="td3"
                      :class="{
                        'fym-good': element.agent_status === 'Active',
                        'fym-fail': element.agent_status === 'Terminated' || element.agent_status === 'Terminated with cause',
                        'fym-almostgood': element.agent_status !== 'Active' && element.agent_status !== 'Terminated' && element.agent_status !== 'Terminated with cause'
                      }"
                      :title="'First sale date: ' + formattedDate(new Date(element.first_sale_dt)) + ', Last sale date: ' + formattedDate(new Date(element.last_sale_dt))"
                      >
                      {{ element.agent_status }}
                    </span>

                    <span class="td1">
                      {{element.agent_rate}}
                    </span>
                    
                    <span class="td4">
                      <i v-if="element.google" class="fa-brands fa-google fym-almostgood text-sm opacity-10" title="gmail created"></i>
                      <i v-else class="hover-pointer fa-brands fa-google fym-almostgood text-sm opacity-10" title="not on gmail"></i>
                      &nbsp;
                      <i v-if="element.google_group === 'no_group'" class="hover-pointer fa-solid fa-user-group fym-almostgood text-sm opacity-10" title="not added to any group" @click="addUserToGoogleGroup(element.id, element.email, element.agency, element.sysrole, element)"></i>
                      <i v-else class="fa-solid fa-user-group fym-good text-sm opacity-10" :title="element.google_group" @click="removeUserFromGoogleGroup(element.id, element.email, element.agency, element.sysrole, element)"></i>
                      &nbsp;
                      <i v-if="element.slack" class="fa-brands fa-slack fym-almostgood text-sm opacity-10" title="slack"></i>
                      <i v-else class="hover-pointer fa-brands fa-slack fym-almostgood text-sm opacity-10" title="not on slack" @click="addUserToSlackGroup"></i>
                      &nbsp;
                      <i v-if="element.onepw" class="fa fa-key fym-good text-sm opacity-10" title="1password"></i>
                      <i v-else class="hover-pointer fa fa-key fym-fail text-sm opacity-10" title="not using 1password"></i>
                      &nbsp;
                      <i v-if="element.enrollhere" class="fa fa-phone fym-good text-sm opacity-10" title="enrollhere"></i>
                      <i v-else class="hover-pointer fa fa-phone fym-fail text-sm opacity-10" title="not on enrollhere"></i>
                      &nbsp;
                      <i v-if="element.sunfire" class="fa fa-sun fym-good text-sm opacity-10" title="sunfire"></i>
                      <i v-else class="hover-pointer fa fa-sun fym-fail text-sm opacity-10" title="not on sunfire"></i>
                      &nbsp;
                      <i v-if="element.bill_id" 
                        :class="element.bill_payBy === '11' ? 'fa fa-money-check-dollar fym-almostgood' : 'fa fa-credit-card fym-good'" class="text-sm opacity-10" :title="element.bill_payBy === '11' ? 'check payment' : 'eft'" @click.prevent="getBillId(`${element.id}`, `${element.firstname}`, `${element.lastname}`, element)">
                      </i>
                      <i v-else 
                        :class="element.bill_payBy === '11' ? 'fa fa-money-check-dollar fym-fail' : 'hover-pointer fa fa-credit-card fym-fail'" class="text-sm opacity-10" :title="element.bill_payBy === '11' ? 'check payment' : 'no bill id'" @click.prevent="getBillId(`${element.id}`, `${element.firstname}`, `${element.lastname}`, element)">
                      </i>
                      &nbsp;
                      
                      <span v-if="isFYMAdmin || isAgencyAdmin">
                        <i v-if="element.signed_contract && element.signed_contract !== 'sent'" class="fa fa-file fym-good text-sm opacity-10" title="signed contract" @click.prevent="getContract(`${element.agent_npn}`)"></i>
                        <i v-else-if="element.signed_contract && element.signed_contract === 'sent'" class="fa fa-file fym-alert text-sm opacity-10" title="signed contract sent" @click.prevent="uploadContract(`${element.agent_npn}`, element)"></i>
                        <i v-else-if="element.signed_contract && element.signed_contract === 'pending'" class="fa fa-file fym-almostgood text-sm opacity-10" title="signed contract uploading" @click.prevent="getContract(`${element.agent_npn}`, element)"></i>
                        <i v-else class="hover-pointer fa fa-file fym-fail text-sm opacity-10 hover-pointer" title="no signed contract" @click.prevent="docusignSend(element.email, element.firstname + ' ' + element.lastname, element)"></i>
                      </span>
                    </span>
                    
                    <br>
                  </li>
              </ul>
              <br><br><br><br><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Hidden File Input -->
    <input 
      type="file" 
      ref="fileInput" 
      style="display: none" 
      @change="handleFileUpload"
    />

  </main>
</template>

<script>
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import router from "@/router";

import { loadGapiInsideDOM } from "gapi-script";

const company = sessionStorage.getItem("company");
const mode = sessionStorage.getItem("mode");

if(mode === 'local' || mode === 'dev') {
  var bucket = 'fym-uploads-dev';
}
else {
  var bucket = 'fym-uploads';
}

var message = "";
var filter = "";

export default {
  name: "Agents",
  data() {
    return {
      accessToken: "",
      showMenu: false,
      elements: [],
      company,
      mode,
      bucket,
      message,
      filter,
      role: sessionStorage.getItem('role'),
      selectedAgency: '0',
      selectedStatus: '0',
      selectedAgent: '',
      selectedSystems: '0',
      sortKey: '',
      sortAsc: true
    }
  },
  computed: {
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  mounted() {
    this.loadGoogleAuth();
  },
  methods: {
    async loadGoogleAuth() {
      try {
        await loadGapiInsideDOM();
        window.gapi.load("client", async () => {
          await window.gapi.client.init({
            apiKey: "AIzaSyDznCBROPBzctnrSXvgkBTbMHYd2Dv12Aw", // Replace with your actual API key (if needed)
            discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/admin/directory_v1/rest"],
            clientId: "695606547314-61l5u7p26dp8lq16tboc7mjsm18f98io.apps.googleusercontent.com", // Replace with actual Client ID
            scope: "https://www.googleapis.com/auth/admin.directory.group.member"
          });
        });
      } catch (error) {
        console.error("Failed to load Google API:", error);
      }
    },
    async addUserToGoogleGroup(agent_id,email, agency, role, element) {

      let group = agency.toLowerCase().replace(/\s+/g, '') + '-agents';

      if (role === 0 || role === 1 || role === 2) {
        group = agency.toLowerCase().replace(/\s+/g, '') + '-managers';
      }
      
      let groupEmail = group.toLowerCase() + "@teamfym.com"; // Replace with actual Google Group email
      let userEmail = email; // Replace with user email to add

      try {
        const tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: "695606547314-61l5u7p26dp8lq16tboc7mjsm18f98io.apps.googleusercontent.com", // Replace with your actual Client ID
          scope: "https://www.googleapis.com/auth/admin.directory.group.member",
          callback: async (response) => {
            if (response.error) {
              console.error("OAuth error:", response.error);
              return;
            }

            this.accessToken = response.access_token;
            console.log("Access Token:", this.accessToken);

            // First check if user is already in the group
            const checkResponse = await fetch(
              `https://www.googleapis.com/admin/directory/v1/groups/${groupEmail}/members/${userEmail}`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${this.accessToken}`,
                },
              }
            );

            if (checkResponse.status === 200) {
              console.log(`${userEmail} is already a member of ${groupEmail}`);

              let response2 = await fetch(`/apis/set_google_group/${groupEmail}/${agent_id}`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  }
                });

                let res = await response2.json();
                element.google_group = res.message;

            } 
            else if (checkResponse.status === 404) {
              // Not found, safe to add
              const addResponse = await fetch(
                `https://www.googleapis.com/admin/directory/v1/groups/${groupEmail}/members`,
                {
                  method: "POST",
                  headers: {
                    Authorization: `Bearer ${this.accessToken}`,
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    email: userEmail,
                    role: "MEMBER",
                  }),
                }
              );

              if (addResponse.ok) {
                console.log(`${userEmail} added to ${groupEmail}`);

                let response2 = await fetch(`/apis/set_google_group/${groupEmail}/${agent_id}`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  }
                });

                let res = await response2.json();
                element.google_group = res.message;

              } 
              else {
                const error = await addResponse.json();
                console.error("Add failed:", error);
              }
            } 
            else {
              const error = await checkResponse.json();
              console.error("Check failed:", error);
            }
          }
        });

        // Request token from the user
        tokenClient.requestAccessToken();
      } 
      catch (error) {
        console.error("Google API Error:", error);
      }
    },
    async removeUserFromGoogleGroup(agent_id,email, agency, role, element) {

      let group = agency.toLowerCase().replace(/\s+/g, '') + '-agents';

      if (role === 0 || role === 1 || role === 2) {
        group = agency.toLowerCase().replace(/\s+/g, '') + '-managers';
      }

      let groupEmail = group.toLowerCase() + "@teamfym.com"; // Replace with actual Google Group email
      let userEmail = email; // Replace with user email to add

      try {
        const tokenClient = google.accounts.oauth2.initTokenClient({
          client_id: "695606547314-61l5u7p26dp8lq16tboc7mjsm18f98io.apps.googleusercontent.com", // Replace with your actual Client ID
          scope: "https://www.googleapis.com/auth/admin.directory.group.member",
          callback: async (response) => {
            if (response.error) {
              console.error("OAuth error:", response.error);
              return;
            }

            this.accessToken = response.access_token;
            console.log("Access Token:", this.accessToken);

            const checkResponse = await fetch(
              `https://www.googleapis.com/admin/directory/v1/groups/${groupEmail}/members/${userEmail}`,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
              }
            );

            if (checkResponse.status === 200) {
              console.log("User is a member. Proceed to delete...");
              // Proceed to DELETE

              // API request to add user to Google Group
              const apiResponse = await fetch(
                `https://www.googleapis.com/admin/directory/v1/groups/${groupEmail}/members/${userEmail}`,
                {
                  method: "DELETE",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.accessToken}`,
                  },
                }
              );
              
              if (apiResponse.ok) {
                //alert("User removed from " + agency + " " + group + " Google Group successfully!");

                let response2 = await fetch(`/apis/set_google_group/no_group/${agent_id}`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  }
                });

                let res = await response2.json();
                element.google_group = res.message;

              } else {
                console.error("Error:", data.error);
              }

            } 
            else {
              console.warn("User not found in the group. No need to delete.");
            }

          }
        });

        // Request token from the user
        tokenClient.requestAccessToken();
      } 
      catch (error) {
        console.error("Google API Error:", error);
      }
    },
    async addUserToSlackGroup() {

      let slackToken = "xoxb-XXXXXXXXXXX-XXXXXXXXXXXXXXXXX"; // Replace with your Slack OAuth token
      let userId = "U12345678"; // Replace with the Slack User ID
      let groupId = "S12345678"; // Replace with the Slack User Group ID

      try {
        const response = await fetch("https://slack.com/api/usergroups.users.update", {
          method: "POST",
          headers: {
            "Authorization": `Bearer ${slackToken}`,
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            usergroup: groupId,
            users: userId // You can pass multiple user IDs as a comma-separated string
          })
        });

        const data = await response.json();

        if (data.ok) {
          alert("User added to the group successfully!");
        } else {
          console.error("Error:", data.error);
          alert("Failed to add user to group.");
        }
      } catch (error) {
        console.error("Slack API Error:", error);
        alert("An error occurred.");
      }
    },
    copyEmailsToClipboard() {
      // Extract emails
      const emailList = this.elements.map(el => el.email).join(', ');

      // Copy to clipboard
      navigator.clipboard.writeText(emailList).then(() => {
        alert('Emails copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy emails: ', err);
      });
    },
    formattedDate(date) {
      if (!(date instanceof Date) || isNaN(date)) {
        date = new Date('1900-01-01T06:00:00Z');
      }
      return date.getFullYear() + '-' +
        String(date.getMonth() + 1).padStart(2, '0') + '-' +
        String(date.getDate()).padStart(2, '0');
    },
    serializeForm(){
      
      let myForm = document.getElementById('search-form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    async getData() {
      let response = await fetch('/apis/Agents');
      this.elements = await response.json();
      this.message = this.elements.length + ' Agents';
    },
    async newelement(type) {
      router.push({ name: type, params: {id: 0}});
    },
    async getBillId(agent_id, agent_fn, agent_ln, element) {

      let response = await fetch("/apis/get_bill_id", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          agent_id,
          agent_fn,
          agent_ln,
          element, // Include the `element` object here
        }),
      });

      //let response = await fetch("/apis/get_bill_id/" + agent_id + '/' + agent_fn + ' ' + agent_ln);
      let data = await response.json();
      if (data.result == 'bill_id_updated') {
        this.message = 'bill.com id updated ';
        element.bill_id = data.message;
      }
      else {
        this.message = 'bill.com id not found';
      }
    }, 
    async docusignSend(email, agent_name, element) {
      this.signed_contract_desc = 'Docusign sent for signature to: ' + email;
      element.signed_contract = 'sent';

      let response = await fetch("/apis/docusign/" + email + '/' + agent_name);
      //this.message = await response.text();
    }, 
    async getContract(agent_npn) {      
      const url = `https://storage.cloud.google.com/` + this.bucket + `/contracts/signed_contract_${agent_npn}.pdf`;
      window.open(url, '_blank');
    },
    async uploadContract(agent_npn, element) {
      element.signed_contract = 'pending';
      this.selectedAgent = agent_npn;
      this.$refs.fileInput.click();     
    },
    // Handle file selection
    handleFileUpload(event) {
      const file = event.target.files[0]; // Get the selected file

      if (file) {
        
        // To upload the file, use FormData
        const formData = new FormData();
        formData.append('file', file);
        formData.append('agent_npn', this.selectedAgent);
        
        // Make API request to upload file
        this.uploadFile(formData);
      }
    },
    // File upload logic
    async uploadFile(formData) {
      try {
        const response = await fetch('/apis/Contract_upload', {
          method: 'POST',
          body: formData,
        });

        if (response.ok) {
          console.log('File uploaded successfully');
                    
        } 
        else {
          console.error('Failed to upload file');
        }
      } catch (error) {
        console.error('Error during file upload:', error);
      }
    },
    async search() {
      var filter = document.getElementById('search').value;
      if (filter == '') {
        let response = await fetch('/apis/Agents');
        this.elements = await response.json();
        this.message = this.elements.length + ' Agents';
      } 
      else {   
        if (filter.length > 1) {  
          let response = await fetch('/apis/agent_search/' + filter);
          this.elements = await response.json();    
          this.message = this.elements.length + ' Agents';      
        }
      }
    },    
    async search2(filter1, filter2, filter3) {      
      if (filter1 === '0' && filter2 === '0' && filter3 === '0') {
        let response = await fetch('/apis/Agents');
        this.elements = await response.json();
        this.message = this.elements.length + ' Agents';
      } 
      else if(filter1 !== '0' && filter2 === '0' && filter3 === '0') {   
        let response = await fetch('/apis/agent_search_by_agency/' + filter1);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 !== '0' && filter2 !== '0' && filter3 === '0') {   
        let response = await fetch('/apis/agent_search_by_agency_and_status/' + filter1 + '/' + filter2);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 !== '0' && filter2 !== '0' && filter3 !== '0') {
        let response = await fetch('/apis/agent_search_by_agency_status_system/' + filter1 + '/' + filter2 + '/' + filter3);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 !== '0' && filter2 === '0' && filter3 !== '0') {
        let response = await fetch('/apis/agent_search_by_agency_system/' + filter1 + '/' + filter3);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 === '0' && filter2 === '0' && filter3 !== '0') {
        let response = await fetch('/apis/agent_search_by_system/' + filter3);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 === '0' && filter2 !== '0' && filter3 !== '0') {
        let response = await fetch('/apis/agent_search_by_status_system/' + filter2 + '/' + filter3);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
      else if(filter1 === '0' && filter2 !== '0' && filter3 === '0') {
        let response = await fetch('/apis/agent_search_by_status/' + filter2);
        this.elements = await response.json();   
        this.message = this.elements.length + ' Agents';
      }
    },
    async copy_npn(cmd) {
      navigator.clipboard.writeText(cmd);
      console.log('copied');
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortAsc = !this.sortAsc;
      } else {
        this.sortKey = key;
        this.sortAsc = true;
      }

      this.elements.sort((a, b) => {
        let x = a[key];
        let y = b[key];

        if (typeof x === 'string') x = x.toLowerCase();
        if (typeof y === 'string') y = y.toLowerCase();

        if (x < y) return this.sortAsc ? -1 : 1;
        if (x > y) return this.sortAsc ? 1 : -1;
        return 0;
      });
    },
    getSortIcon(key) {
      if (this.sortKey !== key) return 'fas fa-sort';
      return this.sortAsc ? 'fas fa-sort-up' : 'fas fa-sort-down';
    }
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getData();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    //setNavPills();
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>