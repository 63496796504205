<template>
    <div class="container top-0 position-sticky z-index-sticky">
      <div class="row">
        <div class="col-12">
          <navbar
            isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
            v-bind:darkMode="true"
            isBtn="bg-gradient-success"
          />
        </div>
      </div>
    </div>
    <br><br><br><br><br><br>
    <main class="mt-0 main-content">
      <section>
      <div class="py-4 container-fluid">
        
        <div class="card shadow-lg mt-n6">
          <div class="card-body p-3">
            <div class="row gx-4">
              <div class="col-auto">
                <div class="avatar avatar-xl position-relative">
                  <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>                
                </div>
              </div>
              <div class="col-auto my-auto">
                <div class="h-100">
                  <h5 class="mb-1"> {{elements[0].firstname}} {{elements[0].lastname}} </h5>
  
                  <p class="mb-0 font-weight-bold text-sm"> </p>
                </div>
              </div>
              <div
                class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
              >
                <div class="nav-wrapper position-relative end-0">
                  <ul
                    class="p-1 bg-transparent nav nav-pills nav-fill"
                    role="tablist"
                  >
  
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <form ref="agentForm" id="agent-form" role="form" method="POST" enctype="multipart/form-data">
              <div class="card-header pb-0">
                <div class="d-flex align-items-center">
                  <p id="message" name="message" class="text-dark font-weight-bolder">{{this.message}} - <a class="hover-pointer" @click="copy_npn(this.agent_npn)" title="Copy NPN to clipboard">{{elements[0].agent_npn}}</a></p>
                  
                  <span class="ms-auto">
                  <argon-button v-if="isEditMode" color="success" size="sm" class="ms-auto" @click.prevent="submitData()">Save</argon-button>
  
                  &nbsp;<a
                  v-else
                  href="#"
                  @click.prevent="enableFields"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"                   
                  ><i class="fa fa-ellipsis-v text-xs"></i> Edit</a>
                  </span>
                </div>
              </div>
              <div class="card-body">
                
                  <span :class="contracting_dt ? 'fym-good' : 'fym-fail'" :title="contracting_dt"><input v-if="!isAgent" id="contracting_check" type="checkbox" :checked="!!contracting_dt" @click="onCheckboxClick('contracting', $event.target.checked)" /> Contracting</span> -> 
                  <span :class="operations_dt ? 'fym-good' : 'fym-fail'" :title="operations_dt"><input v-if="!isAgent" id="operations_check" type="checkbox" :checked="!!operations_dt" @change="onCheckboxClick('operations', $event.target.checked)" /> Operations</span> -> 
                  <span :class="training_dt ? 'fym-good' : 'fym-fail'" :title="training_dt"><input v-if="!isAgent" id="training_check" type="checkbox" :checked="!!training_dt" @change="onCheckboxClick('training', $event.target.checked)" /> Training</span> -> 
                  <span :class="compliance_dt ? 'fym-good' : 'fym-fail'" :title="compliance_dt"><input v-if="!isAgent" id="compliance_check" type="checkbox" :checked="!!compliance_dt" @change="onCheckboxClick('compliance', $event.target.checked)" /> Compliance</span> -> 
                  <span :class="agency_dt ? 'fym-good' : 'fym-fail'" :title="agency_dt"><input v-if="!isAgent" id="agency_check" type="checkbox" :checked="!!agency_dt" @change="onCheckboxClick('agency', $event.target.checked)" /> Agency</span> -> 
                  <span :class="rts_dt ? 'fym-good' : 'fym-fail'" :title="rts_dt"><input v-if="!isAgent" id="rts_check" type="checkbox" :checked="!!rts_dt" @change="onCheckboxClick('rts', $event.target.checked)" /> RTS</span> -> 
                  <span :class="last_sale_dt ? 'fym-good' : 'fym-fail'" :title="'First sale date: ' + new Date(first_sale_dt).toLocaleDateString() + ', Last sale date: ' + new Date(last_sale_dt).toLocaleDateString()">Selling</span>
                  &nbsp;
                  <span class="td1" hidden>
                    <i v-if="my_systems.enrollhere" class="fa fa-phone fym-good text-sm opacity-10" title="enrollhere"></i>
                    <i v-else class="fa fa-phone fym-fail text-sm opacity-10" title="not on enrollhere"></i>
                    &nbsp;
                    <i class="fa fa-sun fym-good text-sm opacity-10" title="sunfire"></i>
                  </span>
  
                <br>
                <div class="row">
                  
                  <div class="col-md-6">
                    <label for="agency" class="form-control-label"
                      >Agency</label
                    >
                    <select id="agency" name="agency" class="form-select" :value="selectedAgency" @mousedown="handleMouseDown">
                      <option v-for="element in agencies" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
  
                    <ul class="ul">  
                      <li v-for="element in my_agencies" :value="element.id" :key="element.id">                      
                        {{ element.agency }} {{ new Date(element.effective_from).toLocaleDateString() }} - {{ new Date(element.effective_to).toLocaleDateString() }}
                      </li>
                    </ul>
                  </div>
  
                  <div class="col-md-6">
                    <label for="team" class="form-control-label"
                      >Team</label
                    >
                    <select id="team_id" name="team_id" class="form-select" :value="selectedTeam" @mousedown="handleMouseDown">
                      <option v-for="element in teams" :value="element.id" :key="element.id">
                        {{ element.name }} ({{ element.grouping }})
                      </option>
                    </select>
                
                  </div>
  
                  <div class="col-md-6">
                    <label for="email" class="form-control-label"
                      >Email address</label
                    >
                    <input class="form-control form-control-default invalid" id="email" name="email" type="email" :value="elements[0].email" autocomplete="email" required/>
                  </div>
                  <div class="col-md-6" hidden>
                    <label for="carrier" class="form-control-label"
                      >Carrier</label
                    >
                    <select id="carrier" name="carrier" class="form-select" :value="selectedCarrier">
                      <option v-for="element in carriers" :value="element.id" :key="element.id">
                        {{ element.name }}
                      </option>
                    </select>
                  </div>
  
                  <div class="col-md-6">
                    <label for="personal_email" class="form-control-label"
                      >Personal Email</label
                    >
                    <input class="form-control form-control-default invalid" id="personal_email" name="personal_email" type="text" :value="elements[0].personal_email"/>
                  </div>
                  <div class="col-md-6">
                    <label for="phone" class="form-control-label"
                      >Agent Phone</label
                    >
                    <input class="form-control form-control-default invalid" id="phone" name="phone" type="text" :value="elements[0].phone" autocomplete="tel"/>
                  </div>
  
                  <div class="col-md-6">
                    <label for="firstname" class="form-control-label"
                      >First name</label
                    >
                    <input class="form-control form-control-default invalid" id="firstname" name="firstname" type="text" :value="elements[0].firstname"/>
                  </div>
                  <div class="col-md-6">
                    <label for="lastname" class="form-control-label"
                      >Last name</label
                    >
                    <input class="form-control form-control-default invalid" id="lastname" name="lastname" type="text" :value="elements[0].lastname"/>
                  </div>
                  
                  <div class="col-md-6">
                    <label for="nick_name" class="form-control-label"
                      >Nick Name</label
                    >
                    <input class="form-control form-control-default invalid" id="nick_name" name="nick_name" type="text" :value="elements[0].nick_name"/>
                  </div>
  
                  <div class="col-md-6">
                    <label for="street" class="form-control-label"
                      >Street</label
                    >
                    <input class="form-control form-control-default invalid" id="street" name="street" type="text" :value="elements[0].street"/>
                  </div>
                  <div class="col-md-6">
                    <label for="city" class="form-control-label"
                      >City</label
                    >
                    <input class="form-control form-control-default invalid" id="city" name="city" type="text" :value="elements[0].city"/>
                  </div>
  
                  <div class="col-md-6">
                    <label for="usstate" class="form-control-label"
                      >State</label
                    >
                    <span>
                      <select id="usstate_id" name="usstate_id" class="form-select" :value="selectedUSState" required>
                        <option v-for="element in usstates" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="zip" class="form-control-label"
                      >Zip</label
                    >
                    <input class="form-control form-control-default invalid" id="zip" name="zip" type="text" :value="elements[0].zip"/>
                  </div>
  
                </div>
  
                <div>
                  <hr>
                </div>
  
                <div class="row">
                  <div class="col-md-6">
                    <label for="agent_npn" class="form-control-label"
                      >Agent NPN</label
                    >
                    <input class="form-control form-control-default invalid" id="agent_npn" name="agent_npn" type="text" :value="elements[0].agent_npn" required @click="copy_npn(this.agent_npn)" title="Copy NPN to clipboard"/>
                  </div>
  
                  <div class="col-md-6">    
                    <label for="agent_manager" class="form-control-label"
                      >Agent Manager</label
                    >
                    <select id="agent_manager" name="agent_manager" class="form-select" :value="selectedAgentManager">
                        <option v-for="element in agent_managers" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>             
                  </div>
  
                  <div class="col-md-6">
                    <label for="agent_type" class="form-control-label"
                      >Agent Type</label
                    >
                    <span>
                      <select id="agent_type_id" name="agent_type_id" class="form-select" :value="selectedAgentType">
                        <option v-for="element in agent_types" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>
                    </span>
                  </div>
                  <div class="col-md-6">
                    <label for="agent_status" class="form-control-label"
                      >Status</label
                    >
                    <span>
                      <select id="agent_status" name="agent_status" class="form-select" :value="selectedStatus" @change="status_change($event.target.value)">
                        <option v-for="element in statuses" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>
                    </span>
                  </div>
  
                  <div class="col-md-6">
                    <label for="dialer" class="form-control-label"
                      >Dialer</label
                    >
                    
                    <select id="dialer" name="dialer" class="form-select" :value="selectedDialer">
                        <option v-for="element in dialers" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>
  
                  </div>
                  <div class="col-md-6">
                    <label for="agent_level" class="form-control-label"
                      >Agent Level</label
                    >
                      <select id="agent_level" name="agent_level" class="form-select" :value="selectedAgentLevel">
                        <option v-for="element in agent_levels" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>
                  </div>
  
                  <div class="col-md-6">
                    <label for="start_date" class="form-control-label"
                      >Start Date</label
                    >
                    <input class="form-control form-control-default invalid" id="start_date" name="start_date" type="date" :value="elements[0].start_date"/>
                  </div>
                  <div class="col-md-6">
                    <label for="end_date" class="form-control-label"
                      >End Date</label
                    >
                    <input class="form-control form-control-default invalid" id="end_date" name="end_date" type="date" :value="elements[0].end_date"/>
                  </div>
                 
  
                  <div class="col-md-6">
                    <label for="sales_type" class="form-control-label"
                      >Sales Type</label
                    >
                    <span>
                    <select id="sales_type_id" name="sales_type_id" class="form-select" :value="selectedSalesType">
                        <option v-for="element in sales_types" :value="element.id" :key="element.id">
                          {{ element.name }}
                        </option>
                      </select>
                    </span>
                  </div>
  
                  <div class="col-md-6">
                    <label for="ein" class="form-control-label"
                      >EIN</label
                    >
                    <input class="form-control form-control-default invalid" id="ein" name="ein" type="text" :value="elements[0].ein"/>
                  </div>
  
                  <div class="col-md-6">
                    <label for="unl_id" class="form-control-label"
                      >UNL Id</label
                    >
                    <input class="form-control form-control-default invalid" id="unl_id" name="unl_id" type="text" :value="elements[0].unl_id"/>
                  </div>
  
                  <div class="col-md-6">
                    <label for="san" class="form-control-label"
                      >SAN</label
                    >
                    <input class="form-control form-control-default invalid" id="san" name="san" type="text" :value="elements[0].san"/>
                  </div>
  
                  <div class="col-md-6">
                    <label for="bill_id" class="form-control-label"
                      >Bill ID</label
                    >
                    <input class="form-control form-control-default invalid" id="bill_id" name="bill_id" type="text" :value="elements[0].bill_id"/>
                  </div>
  
                  <div v-if="isFYMAdmin" class="col-md-6">
                  </div>
  
                  <div class="col-md-6">                  
                  </div>
                  <div class="col-md-6">
                  </div>
  
                  <div>
                    <hr>
                  </div>
                  
                  <div class="card-header pb-0">
                    <div class="d-flex align-items-center">
                      <p id="message" name="message" class="text-dark font-weight-bolder">Agent Rates</p>
                      <argon-button v-if="!isAgent" color="success" size="sm" class="ms-auto" @click.prevent="newelement('AgentRate')">Create Agent Rate</argon-button>
                    </div>
                      <ul class="ul">  
                        <li v-for="element in my_rates" :value="element.id" :key="element.id">
  
                          <span class="td1">
  
                            <!-- Conditional green dot for current rate -->
                            <i v-if="element.rate_status === 'current'" class="fa fa-circle fym-good text-sm opacity-10"></i>
                            <i v-else-if="element.rate_status === 'future'" class="fa fa-circle fym-future text-sm opacity-10"></i>
                            <i v-else class="fa fa-circle fym-alert text-sm opacity-10"></i>
                            &nbsp;
                            <i class="fa fa-ellipsis-v text-xs"></i>
                            <router-link v-if="!isAgent" :to="{ name: 'AgentRate', params: {id: element.id, agent_id: this.$route.params.id}}" class="font-weight-bold">
                              &nbsp;<a
                              href="#"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"                   
                              > Edit</a>
                            </router-link>
                          </span>                         
                          {{ element.rate_type }} {{ element.rate }} for product: {{ element.product }} {{ new Date(element.effective_from).toLocaleDateString() }} - {{ new Date(element.effective_to).toLocaleDateString() }}
  
                        </li>
                      </ul>
                  </div>
  
                  <div>
                    <hr>
                  </div>
                  
                  <div v-if="isFYMAdmin || isAgencyAdmin" class="card-header pb-0">
                    <div class="d-flex align-items-center">
                      <p id="message" name="message" class="text-dark font-weight-bolder">Overrides</p>
                      <argon-button v-if="!isAgent" color="success" size="sm" class="ms-auto" @click.prevent="newelement('Override')">Create Override</argon-button>
                    </div>
                      <ul class="ul">  
                        <li v-for="element in my_overrides" :value="element.id" :key="element.id">
                          
                          <span class="td1">
                            <i class="fa fa-ellipsis-v text-xs"></i>
                            <router-link v-if="!isAgent" :to="{ name: 'Override', params: {id: element.id, agent_id: this.$route.params.id}}" class="font-weight-bold">
                              &nbsp;<a
                              href="#"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"                   
                              > Edit</a>
                            </router-link>
                          </span>
                          $ {{ element.amount }} paid to: {{ element.paid_to }} team: {{ element.team }}
  
                        </li>
                      </ul>
                  </div>
                  
                  <div class="card-header pb-0">
                    <div class="d-flex align-items-center">
                      <p id="emessage" name="emessage" class="text-dark font-weight-bolder">Sunfire live Enrollments - {{ my_enrollments.length }}</p>
                    </div>
                      Between
                      
                      <input 
                        class="form-control invalid" 
                        id="enrollments_from" 
                        name="enrollments_from" 
                        type="date" 
                        v-model="enrollments_from" 
                        @change="getEnrollments" 
                      />
                     
                      and
  
                      <input 
                        class="form-control invalid" 
                        id="enrollments_to" 
                        name="enrollments_to" 
                        type="date" 
                        v-model="enrollments_to" 
                        @change="getEnrollments" 
                      />
  
                      <br><br>
                      <ul class="ul">  
                        <li v-for="element in my_enrollments" :value="element.current_status" :key="element.current_status">
                          
                          <span class="td3">{{ element.last_updated }}</span> 
                          <span class="td2">{{ element.enrollment_code }}</span>
                          <span class="td1">{{ element.firstName }}</span>
                          <span class="td1">{{ element.lastName }}</span>
                          <span class="td5">{{ element.plan }}</span>
  
                        </li>
                      </ul>
                  </div>
                  
                  <div class="card-header pb-0">
                    
                    <div class="d-flex align-items-center">
                      <p id="signed_contract" name="signed_contract" class="text-dark font-weight-bolder">Contract Info</p>                    
                    </div>
  
                    <a v-if="this.signed_contract && this.signed_contract !== 'sent'" href=# :class="getContractClass" title="view signed contract" @click.prevent="getContract(`${this.agent_npn}`, this)">Signed Contract</a>
                    <a v-else-if="this.signed_contract && this.signed_contract === 'sent'" href="javascript:void(0);" style="pointer-events: none;" :class="getContractClass" title="signed contract sent">Signed Contract sent</a>
                    <a v-else-if="isFYMAdmin" href="javascript:void(0);" style="" :class="getContractClass" title="Send signed contract" @click.prevent="docusignSend(this.email, this.agent_name)">Send Signed Contract</a>
                    <a v-else href="javascript:void(0);" style="" :class="getContractClass" title="no signed contract" >No Signed Contract</a>
  
                  </div>
  
                  <div v-if="isFYMAdmin" class="card-header pb-0">
                    <div class="d-flex align-items-center">
                      <p id="message" name="message" class="text-dark font-weight-bolder">Agent Account balance: ${{ loans_total[0].balance }}</p>
                      <argon-button v-if="!isAgent" color="success" size="sm" class="ms-auto" @click.prevent="newelement('Loan')" hidden>Create Loan</argon-button>
                    </div>
                      <ul class="ul">  
                        <li>
                          <span class="td4">
                          Total loans: ${{ loans_total[0].total_loans }}
                          </span>
                        </li>
                        <li>
                          <span class="td4">
                          Total repayments: ${{ loans_total[0].total_repayments }}
                          </span>
                        </li>
                      </ul>
  
                      <div class="d-flex align-items-center">
                        <p id="loans_label" name="loans_label" class="text-dark font-weight-bolder">Loans</p>
                      </div>
  
                      <ul class="ul">  
                        <li v-for="element in my_loans" :value="element.id" :key="element.id">
  
                          <span class="td1">
  
                            <!-- Conditional green dot for paid up loan -->
                            <i v-if="element.loan_status === 'paid'" class="fa fa-circle fym-good text-sm opacity-10"></i>
                            <i v-else-if="element.loan_status === 'partial'" class="fa fa-circle fym-future text-sm opacity-10"></i>
                            <i v-else class="fa fa-circle fym-alert text-sm opacity-10"></i>
                            &nbsp;
                            <i class="fa fa-ellipsis-v text-xs"></i>
                            <router-link v-if="!isAgent" :to="{ name: 'Loan', params: {id: element.id, agent_id: this.$route.params.id}}" class="font-weight-bold">
                              &nbsp;<a
                              href="#"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"                   
                              > View</a>
                            </router-link>
                          </span>                         
                          {{ new Date(element.loan_date).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}) }} - <span class="td1">$ {{ element.amount }}</span> {{ element.reason }}
  
                        </li>
                      </ul>
  
                      <div class="d-flex align-items-center">
                        <p id="repayments_label" name="repayments_label" class="text-dark font-weight-bolder">Repayments</p>
                      </div>
  
                      <ul class="ul">  
                        <li v-for="element in my_repayments" :value="element.id" :key="element.id">
  
                          <span class="td1">
  
                            <!-- Conditional green dot for paid up loan -->
                            <i v-if="element.repayment_status === 'paid'" class="fa fa-circle fym-good text-sm opacity-10"></i>
                            <i v-else-if="element.repayment_status === 'scheduled'" class="fa fa-circle fym-future text-sm opacity-10"></i>
                            <i v-else class="fa fa-circle fym-alert text-sm opacity-10"></i>
                            &nbsp;
                            <i class="fa fa-ellipsis-v text-xs"></i>
                            <router-link v-if="!isAgent" :to="{ name: 'Repayment', params: {id: element.id, agent_id: this.$route.params.id}}" class="font-weight-bold">
                              &nbsp;<a
                              href="#"
                              class="text-secondary font-weight-bold text-xs"
                              data-toggle="tooltip"                   
                              > View</a>
                            </router-link>
                          </span>                         
                          {{ new Date(element.payment_date).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}) }} - <span class="td1">$ {{ element.amount }}</span> {{ element.notes }}
  
                        </li>
                      </ul>
  
                      <div class="d-flex align-items-center">
                      <p id="audit_trail_label" name="audit_trail_label" class="text-dark font-weight-bolder">Audit Trail</p>
                    </div>

                    <ul class="ul">  
                      <li v-for="element in my_audit_trail" :value="element.id" :key="element.id">
                        <i class="fa fa-ellipsis-v text-xs"></i>
                        {{ new Date(element.created_ts).toLocaleString('en-US', { 
                          year: 'numeric', 
                          month: '2-digit', 
                          day: '2-digit', 
                          hour: '2-digit', 
                          minute: '2-digit', 
                          second: '2-digit', 
                          hour12: false 
                        }) }} 
                        {{ element.field }} changed from: {{ element.old_value }} to: {{ element.new_value }} by {{ element.created_by }}
                      </li>
                    </ul>

                  </div>
  
                </div>
  
              </div>
            </form>
            </div>
          </div>
        </div>
      </div>
      </section>
    </main>
  </template>
  
  <script>
  
  import router from "@/router";
  import setTooltip from "@/assets/js/tooltip.js";
  import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
  
  import ArgonButton from "@/components/ArgonButton.vue";
  import { isReadonly, readonly } from "vue";
  
  const body = document.getElementsByTagName("body")[0];
  const form = document.forms['agent-form'];
  
  var message = "New Agent";
  const sysname = sessionStorage.getItem("sysname");
  const mode = sessionStorage.getItem("mode");
  
  if(mode === 'local' || mode === 'dev') {
    var bucket = 'fym-uploads-dev';
  }
  else {
    var bucket = 'fym-uploads';
  }
  
  var selectedUSState = 43;
  var selectedCarrier = 1;
  var selectedAgency = sessionStorage.getItem("agency_id");
  var selectedTeam = 1;
  var selectedAgentType = 1;
  var selectedStatus = 2;
  var selectedSalesType = 1;
  var selectedAgentLevel = 1;
  var selectedAgentManager = 1;
  var selectedDialer = 1;
  var my_signed_contract;
  
  var agent_npn;
  var AgencyReadOnly = true;
  
  var selectedYear = String(new Date().getFullYear());
  var selectedMonth = new Date().getMonth();
  var selectedEnrollPeriod = '1';
  
  export default {
    name: "agent_form",
    props: ['id'],
    data() {
      return {
        auditTrail: [],
        mode,
        bucket,
        agent_npn,
        agent_name: '',
        showMenu: false,
        elements: [{id:0}],
        agent_managers: [],
        selectedAgentManager,
        carriers: [],
        selectedCarrier,
        agencies: [],      
        selectedAgency,
        teams: [],
        selectedTeam,
        agent_types: [],
        selectedAgentType,
        statuses: [],
        selectedStatus,
        sales_types: [],
        selectedSalesType,
        usstates: [],
        selectedUSState,
        agent_levels: [{'id':1,'name':'Base'}, {'id':2,'name':'Medium'}, {'id':3,'name':'High'}, {'id':0,'name':'Undefined'}],
        selectedAgentLevel,
        my_rates: [],
        loans_total: [{'total_loans':0,'total_repayments':0,'balance':0}],
        my_loans: [],
        my_repayments: [],
        my_audit_trail: [],
        my_overrides: [],
        my_statuses: [],
        my_enrollments: [],
        my_signed_contract,
        my_agencies: [],
        message,
        sysname,
        dialers: [{'id':1,'name':'Convoso'}, {'id':2,'name':'External FYM'}, {'id':3,'name':'Internal IFG'}, {'id':0,'name':'Enrollhere'}],
        selectedDialer: 0,
        role: sessionStorage.getItem('role'),
        super: sessionStorage.getItem('sysname'),
        form: {
          method: '',
          icon: ''
        },
        token: '',
        fetchResponse: {},
        fetchError: '',
        isFieldDisabled: true,
        errors: {},
        years: [{'id':2025,'name':'2025'}, {'id':2024,'name':'2024'}, {'id':2023,'name':'2023'}],
        selectedYear,
        months: [{'id':1,'name':'January'}, {'id':2,'name':'February'},{'id':3,'name':'March'}, {'id':4,'name':'April'},
        {'id':5,'name':'May'}, {'id':6,'name':'June'},{'id':7,'name':'July'}, {'id':8,'name':'August'},
        {'id':9,'name':'September'}, {'id':10,'name':'October'},{'id':11,'name':'November'}, {'id':12,'name':'December'}
        ],
        selectedMonth,
        enroll_periods: [{'id':1,'name':'Period 1'}, {'id':16,'name':'Period 2'}],     
        selectedEnrollPeriod,
        AgencyReadOnly,
        res: '',
        unl_id: '',
        contracting_dt: '',
        operations_dt: '',
        training_dt: '',
        compliance_dt: '',
        agency_dt: '',
        rts_dt: '',
        first_sale_dt: '',
        last_sale_dt: '',
        my_systems: [],
        enrollments_from: new Date().toISOString().split('T')[0],
        enrollments_to: new Date().toISOString().split('T')[0],
        signed_contract: '',      
        signed_contract_desc: 'Signed Contract',
        isEditMode: false, // Reactive state for readonly fields
      }
    },
    computed: {
      currentElement() {
        return this.elements[0];
      },
      isSuper() {
        return this.super === 'jason' || this.super ==='nell';
      },
      isFYMAdmin() {
        return this.role === 'FYM Admin';
      },
      isAgencyAdmin() {
        return this.role === 'Agency Admin';
      },
      isAgentManager() {
        return this.role === 'Agent Manager';
      },
      isAgent() {
        return this.role === 'Agent';
      },
      getContractClass() {
        if (this.signed_contract && this.signed_contract !== 'sent') {
          return 'fym-good';
        } 
        else if (this.signed_contract && this.signed_contract === 'sent') {
          return 'fym-alert';
        } 
        else {
          return 'fym-fail';
        }
      }
    },
    watch: {
      contracting_dt(newVal, oldVal) {
        this.handleAuditChange('contracting_dt', oldVal, newVal);
      },
      operations_dt(newVal, oldVal) {
        this.handleAuditChange('operations_dt', oldVal, newVal);
      },
      training_dt(newVal, oldVal) {
        this.handleAuditChange('training_dt', oldVal, newVal);
      }, 
      compliance_dt(newVal, oldVal) {
        this.handleAuditChange('compliance_dt', oldVal, newVal);
      },
      agency_dt(newVal, oldVal) {
        this.handleAuditChange('agency_dt', oldVal, newVal);
      },
      rts_dt(newVal, oldVal) {
        this.handleAuditChange('rts_dt', oldVal, newVal);
      },
      last_sale_dt(newVal, oldVal) {
        this.handleAuditChange('last_sale_dt', oldVal, newVal);
      },
      // repeat for each _dt field...
    },
    methods: {
      handleAuditChange(field, oldVal, newVal) {
        if (oldVal !== newVal) {
          fetch(`/apis/audit/at_agents/${this.$route.params.id}/${this.elements[0].id}/${field}/${oldVal}/${newVal}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(this.serializeForm())
          });
        }
      },
      // Set all input fields to readonly
      // Set all fields (inputs and dropdowns) to readonly/disabled
      setFieldsReadonly() {
        const form = this.$refs.agentForm; // Use $refs instead of document.forms
        if (form) {
          this.isEditMode = false;
  
          // Disable input fields
          const inputs = form.querySelectorAll("input");
          inputs.forEach((input) => {
            input.readOnly = true; // Set text fields to readonly
          });
  
          // Disable select (dropdown) fields
          const selects = form.querySelectorAll("select");
          selects.forEach((select) => {
            select.disabled = true; // Disable dropdowns
          });
  
          // Disable buttons
          const buttons = form.querySelectorAll("button");
          buttons.forEach((button) => {
            button.disabled = true; // Disable buttons
            button.hidden = true; // Hide buttons
          });
  
        }
      },
      // Enable all fields (inputs and dropdowns)
      enableFields() {
        const form = this.$refs.agentForm; // Use $refs instead of document.forms
        if (form) {
          this.isEditMode = true;

          // Enable input fields
          const inputs = form.querySelectorAll("input");
          inputs.forEach((input) => {
            input.readOnly = false; // Remove readonly from text fields
          });
  
          // Enable select (dropdown) fields
          const selects = form.querySelectorAll("select");
          selects.forEach((select) => {
            select.disabled = false; // Enable dropdowns
          });
  
          // Disable buttons
          const buttons = form.querySelectorAll("button");
          buttons.forEach((button) => {
            button.disabled = false; // Disable buttons
            button.hidden = false; // Show buttons
          });
  
        }
      },
      async getContract(agent_npn) {
        //const url = `uploads/contracts/signed_contract_${agent_npn}.pdf`;
        const url = `https://storage.cloud.google.com/` + this.bucket + `/contracts/signed_contract_${agent_npn}.pdf`;
        window.open(url, '_blank');
      },
      async onCheckboxClick(stage, isChecked) {
        let response = await fetch("/apis/Agent_stage_update/" + this.$route.params.id + '/' + stage + '/' + isChecked + '/' + this.agent_name);
        this.res = await response.json();
        this.message = 'agent stage updated';
        
        this[`${stage}_dt`] = this.res["dt:"];
      },
      getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0).getDate();
      },
      handleMouseDown(event) {
        if (this.role !== 'FYM Admin') {
          event.preventDefault();
        }
      },
      changeAgency() {
        let response = fetch("/apis/AgentManagers/" + selectedAgency);
        this.agent_managers = response.json(); 
  
        let response2 = fetch("/apis/Teams/" + selectedAgency);
        this.teams = response2.json(); 
      },
      serializeForm(){
        
        let myForm = this.$refs.agentForm; // Use $refs instead of document.forms
        let formData = new FormData(myForm);
        const data = {}; 
  
        // Temporarily enable the field
        this.isFieldDisabled = false;
  
        for (let [key, val] of formData.entries()) {
          Object.assign(data, {[key]: val});
        }
        
        // Optionally re-disable the field
        this.isFieldDisabled = true;
  
        return data;
      },
      async checkAuth() {
        let response = await fetch("/apis/auth");
        this.loggedin = await response.json();
        if(this.loggedin =="notloggedin") {
          location.href="/home";
        }
      },
      async getData(agent_id) {
        let response = await fetch("/apis/element_load/at_agents/" + agent_id);
        this.elements = await response.json();
        this.agent_npn = this.elements[0]['agent_npn'];
        this.firstname = this.elements[0]['firstname'];
        this.lastname = this.elements[0]['lastname'];
        this.agent_name = this.elements[0]['firstname'] + ' ' + this.elements[0]['lastname'];
        this.email = this.elements[0]['email'];
        this.personal_email = this.elements[0]['personal_email'];
        this.message = this.elements[0]['firstname'] + ' ' + this.elements[0]['lastname'];
        this.selectedAgency = this.elements[0]['agency_id']; 
        this.selectedTeam = this.elements[0]['team_id']; 
        this.selectedCarrier = this.elements[0]['carrier_id']; 
        this.selectedAgentType = this.elements[0]['agent_type_id']; 
        this.selectedStatus = this.elements[0]['agent_status_id']; 
        this.selectedSalesType = this.elements[0]['sales_type_id']; 
        this.selectedUSState = this.elements[0]['usstate_id']; 
        this.selectedAgentManager = this.elements[0]['agent_manager_id'];
        this.selectedDialer = this.elements[0]['dialer']; 
        this.selectedAgentLevel = this.elements[0]['agent_level']; 
        this.unl_id = this.elements[0]['unl_id']; 
  
        this.contracting_dt = this.elements[0]['contracting_dt'];
        this.operations_dt = this.elements[0]['operations_dt'];
        this.training_dt = this.elements[0]['training_dt'];
        this.compliance_dt = this.elements[0]['compliance_dt'];
        this.agency_dt = this.elements[0]['agency_dt'];
        this.rts_dt = this.elements[0]['rts_dt'];
        this.first_sale_dt = this.elements[0]['first_sale_dt'];
        this.last_sale_dt = this.elements[0]['last_sale_dt'];
        this.signed_contract = this.elements[0]['signed_contract'];
  
        this.street = this.elements[0]['street'];
        this.city = this.elements[0]['city'];
        this.state = this.selectedUSState;
        this.zip = this.elements[0]['zip'];

        this.$watch(
          () => this.elements[0],
          (newVal, oldVal) => {        
            if (!oldVal) return;
  
            for (const key in newVal) {
              if (newVal[key] !== oldVal[key]) {

                if(key !=='modified_ts') {

                  fetch(`/apis/audit/at_agents/${agent_id}/${this.$route.params.id}/${key}/${oldVal[key]}/${newVal[key]}`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    credentials: 'include',
                    body: JSON.stringify(this.serializeForm())
                  });
                
                }

              }
            }
          },
          { deep: true }
        );

      },
      async getAgentRates() {        
        let response = await fetch("/apis/view/AgentRates/" + this.$route.params.id);
        this.my_rates = await response.json();
      },
      async getLoans() {        
        let response = await fetch("/apis/view/Loans/" + this.$route.params.id);
        this.my_loans = await response.json();
      },
      async getRepayments() {        
        let response = await fetch("/apis/view/Repayments/" + this.$route.params.id);
        this.my_repayments = await response.json();
      },
      async getAuditTrail() {        
        let response = await fetch("/apis/view/AuditTrail/" + this.$route.params.id);
        this.my_audit_trail = await response.json();
      },
      async getLoansTotal() {        
        let response = await fetch("/apis/loans_total/" + this.$route.params.id);
        this.loans_total = await response.json();
      },
      async getAgencyHistory() {        
        let response = await fetch("/apis/view/AgencyHistory/" + this.$route.params.id);
        this.my_agencies = await response.json();
      },
      async getOverrides() {        
        let response = await fetch("/apis/view/Overrides/" + this.$route.params.id);
        this.my_overrides = await response.json();
      },
      async getSystems() {        
        let response = await fetch("/apis/Agents/Systems/" + this.$route.params.id);
        this.my_systems = await response.json();
      },
      async getPolicyStatuses() {
        var date_from = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedEnrollPeriod;
        //console.log(date_from);
        if(this.selectedEnrollPeriod == 1) {
          var eom = 15;
        }
        else {        
          var eom = this.getLastDayOfMonth(this.selectedYear, this.selectedMonth-1);
        }
        var date_to = this.selectedYear + '-' + this.selectedMonth + '-' + eom;
        //console.log(date_to);
        let response = await fetch("/apis/PolicyStatuses/" + this.$route.params.id + "/" + date_from + "/" + date_to);
        this.my_statuses = await response.json();
      },
      async getEnrollments() {            
        let response = await fetch("/apis/Enrollments/" + this.$route.params.id + "/" + this.enrollments_from + '/' + this.enrollments_to);
        this.my_enrollments = await response.json();
      },
      async newelement(type) {
        router.push({ name: type, params: {id: 0, agent_id: this.$route.params.id}});
      },
      async getLookups() {
  
        let response1 = await fetch("/apis/view/Carriers");
        this.carriers = await response1.json();
  
        let response2 = await fetch("/apis/view/Agencies");
        this.agencies = await response2.json();
       
        let response3 = await fetch("/apis/get_lu/agent_types");
        this.agent_types = await response3.json();
  
        let response4 = await fetch("/apis/get_lu/agent_statuses");
        this.statuses = await response4.json();
  
        let response5 = await fetch("/apis/get_lu/sales_types");
        this.sales_types = await response5.json();
  
        let response6 = await fetch("/apis/get_lu/usstates");
        this.usstates = await response6.json();
  
        let response7 = await fetch("/apis/AgentManagers/" + this.selectedAgency);
        this.agent_managers = await response7.json();
  
        let response8 = await fetch("/apis/Teams/" + this.selectedAgency);
        this.teams = await response8.json();
  
      },
      async copy_npn(cmd) {
        navigator.clipboard.writeText(cmd);
        console.log('copied');
      },
      async status_change(st) {   
         
        const today = new Date();
        const formattedDate = today.getFullYear() + '-' + String(today.getMonth() + 1).padStart(2, '0') + '-' + String(today.getDate()).padStart(2, '0');
  
        this.selectedStatus = st;
        
        if(st=='1') {
          //Active
          this.elements[0].start_date = formattedDate;
          this.elements[0].end_date = '';
          
          let response = await fetch("/apis/AgentStatus_change/" + this.$route.params.id + '/' + st);
          this.message = await response.json();
  
        }
        else if(st=='2') {                
          //Contracting        
        }
        else if(st=='3') {
          //Inactive
        }
        else if(st=='4') {
          //Did not finish
          this.elements[0].end_date = formattedDate;
  
          let response = await fetch("/apis/TerminateAgent/" + this.$route.params.id + '/' + st);
          this.message = await response.json();
          
          this.getAgentRates();
  
        }
        else if(st=='5') {
          //Terminated
          this.elements[0].end_date = formattedDate;
  
          let response = await fetch("/apis/TerminateAgent/" + this.$route.params.id + '/' + st);
          this.message = await response.json();
  
          this.getAgentRates();
                    
        }
        else if(st=='6') {
          //Terminated with cause
          this.elements[0].end_date = formattedDate;
  
          let response = await fetch("/apis/TerminateAgent/" + this.$route.params.id + '/' + st);
          this.message = await response.json();
  
          this.getAgentRates();
        }
  
      },
      validateForm() {
        var val = true;
  
        const items = ['email'];
  
        for (const item of items) {
  
          if(document.getElementById(item).value === '') {
  
            var pcit = this.convertToTitleCase(item).replace('_',' ');
  
            var msg = 'Please enter a value in the ' + pcit + ' field';
            document.getElementById(item).focus();
            this.message = msg;
            val = false;
          }
  
        }
  
        return val;
        
      },
      async getContractLink() {
        let response = await fetch("/apis/get_agent_npn/" + this.$route.params.id);
        this.agent_npn = await response.json();
  
        return this.agent_npn
      },
      convertToTitleCase(str) {
        if (!str) {
            return ""
        }
  
        return str.toLowerCase().split(' ').map(function (word) {
          return word.charAt(0).toUpperCase().concat(word.substr(1));
        }).join(' ');
      },
      validEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
      },
      async docusignSend(email, agent_name) {
        this.signed_contract_desc = 'Docusign sent for signature to: ' + email;
        this.signed_contract = 'sent';
  
        let response = await fetch("/apis/docusign/" + email + '/' + agent_name);
        this.message = await response.text();
      },
      async createBillVendor(agent_name, email, address1, city, state, zip) {      
        let response = await fetch("/apis/create_bill_vendor/" + agent_name + '/' + email + '/' + address1 + '/' + city + '/' + state + '/' + zip);
        this.message = await response.text();
      },
      submitData: function() {
  
        if (this.validateForm()) {
  
          var action = 'create';
          var api = 'create';
          var element_id = this.$route.params.id;
  
          if (element_id > 0) {
            action = 'update';
            api = 'update/' + element_id;
          }

          fetch('/apis/Agent_' + api, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.serializeForm()),
          })
  
          .then((res) => res.json())
          .then((data) => {
            if (data.result === 'Agent_' + action + 'd') {
              this.getData(data.agent_id);
            }
            else {
              //router.push('/');
            }
            this.message = data.message;
          })
          .catch((err) => console.log(err));
        }
        else {
          console.log('Agent form validation failed.');
        }
  
      },
      sendFetch() {
  
        let formData = new FormData();
        formData.append("file", document.forms[0].file.files[0]);
  
        fetch( '/apis/file_upload', {
            method: 'POST',
            headers: {
                //'Authorization': 'Bearer '+this.token,
                //'Accept': 'application/json',
                //'Content-Type': 'multipart/form-data'
            },
            body: formData
        } )
        .then( function( response ){
            if( response.status != 201 ){
              throw response.status;
            }else{
              return response.json();
            }
        }.bind(this))
        .then( function( data ){
            this.fetchResponse = data;
        }.bind(this))
        .catch( function( error ){
            this.fetchError = error;
        }.bind(this));
          },
          handleFileUpload( e ){
            //console.log(e.target.files[0]);
            this.form.icon = e.target.files[0];
      }
    },
    components: { Navbar,  ArgonButton },
    created() {
  
      this.checkAuth();
      
      if (this.$route.params.id > 0) {
        this.getData(this.$route.params.id);
        this.getAgentRates();
        this.getLoans();
        this.getRepayments();
        this.getLoansTotal();
        this.getAgencyHistory();
        this.getOverrides();
        this.getPolicyStatuses();
        this.getEnrollments();
        this.getSystems();
        this.getAuditTrail();

        //this.originalElement = JSON.parse(JSON.stringify(this.elements[0]));
        this.originalElement = this.serializeForm(); // this will hold the original form data as an object
      }
      else {
        this.elements[0].id = "0";
      }
  
      this.getLookups();
    },
    mounted() {
      this.setFieldsReadonly();
      this.$store.state.isAbsolute = true;
      //setNavPills();
      setTooltip();
    },
    beforeMount() {
      this.$store.state.imageLayout = "profile-overview";
      this.$store.state.showNavbar = false;
      this.$store.state.showFooter = true;
      this.$store.state.hideConfigButton = true;
      body.classList.add("profile-overview");
    },
    beforeUnmount() {
      this.$store.state.isAbsolute = false;
      this.$store.state.imageLayout = "default";
      this.$store.state.showNavbar = false;
      this.$store.state.showFooter = true;
      this.$store.state.hideConfigButton = false;
      body.classList.remove("profile-overview");
    }

  };
  </script>